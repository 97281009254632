<template>
    <slot />

    <ModalsContainer />
</template>

<script setup lang="ts">
import {ModalsContainer} from "vue-final-modal";

useHead({
    htmlAttrs: {
        // class: 'dark'
    }
})
</script>